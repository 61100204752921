<script>
import jsPDF from 'jspdf';
import "jspdf-autotable";
import moment from 'moment';

export default {
    props: {
      sale:Object,        
    },
    components: {
    },
    data() {
        return {
            business:null,
        };
    },
    mounted() {
    this.business = JSON.parse(sessionStorage.business_location);
    this.$parent.$on("printReceipt", this.handlePrint);
  },
  beforeUnmount() {
    this.$parent.$off("printReceipt", this.handlePrint);
  },
  methods: {
  async handlePrint() {
    // Document of 120mm wide and 180mm high
    const doc = jsPDF('p', 'mm', [120, 180]);
    var startY=10
    // Add watermark (shop logo) covering the whole receipt
    const logo = new Image();
    let baseUrl=window.$http.toString().replace('api/','media/')
    let logoUrl = this.business.business__watermarklogo?`${baseUrl}${this.business.business__watermarklogo}`:require('@/assets/logo.png');
    //alert(logoUrl);
    logo.src = logoUrl; // Replace 'path/to/shop-logo.png' with the actual path to your shop logo
    doc.addImage(logo, 'PNG', 2, 10, 100,40);
    var middle_w=(doc.internal.pageSize.width/2);
    var doc_width=doc.internal.pageSize.width;
    doc.addFont("Tahoma", "Tahoma","light");
    doc.setFontSize(14);
    doc.text(`${moment(new Date()).format('DD/MM/YYYY HH:MM')}`, 5, startY);
    doc.setFontSize(12);
    doc.line(5, startY+1, 49, startY+1);
    doc.text(`Sale Receipt #${this.sale.customer}`, doc_width-5, startY, { align: "right" });
    // Shop Info
    doc.setFontSize(16);
    doc.text(`${this.business.location_name}`, middle_w-5, startY+22, { align: "center" });
    // Add rectangle for business details
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(middle_w-58, startY+23, doc_width-4, 24, 1, 1, "FD");
    doc.setTextColor(0.5, 0.5, 0.5);
    // Add text content for business details
    doc.setFontSize(12);
    doc.setTextColor(0); // Black color for the text
    doc.text(`${this.business.city}`, middle_w-10, startY+30, { align: "center" }); // Replace "Location: Address" with your actual business location
    doc.text(`MTN: ${this.business.contact_number}`, middle_w-10, startY+35, { align: "center" }); // Replace "MTN: XXXXXX" with your actual MTN number
    doc.text(`TEL 1: ${this.business.alternate_contact_number}`, middle_w-10, startY+40, { align: "center" }); // 
    doc.text("TEL 2: XXXXXXXXXXX", middle_w-10, startY+45, { align: "center" }); //
    doc.setFontSize(16);
    // Add sale details
    doc.text(`BILL TO: ${this.sale.customer}`, 10, startY+55);
    doc.text(`Cashier: ${this.sale.attendant}`, middle_w-20, startY+65);
    // Add sale items
    doc.setFontSize(12);
    startY+=68;
    // Add table headers
    var headers = ['Item Name', 'Qty', 'Price', 'Ext. Price'];
    var body = this.sale.sales_items.map(item => [item.product.title, item.quantity, new Intl.NumberFormat().format(item.unit_price), new Intl.NumberFormat().format(item.unit_price*item.quantity)]);
    doc.addFont("Tahoma", "Tahoma","bold");
    doc.setFontSize(14);
    // Push the subtotal and total rows
    body.push([{content: 'Sub Total:', colSpan: 3}, new Intl.NumberFormat().format(this.sale.sub_total)]);
    body.push([{content: 'RECEIPT TOTAL:', colSpan: 3}, new Intl.NumberFormat().format(this.sale.total)]);
    doc.autoTable({
        head: [headers],
        body: body,
        startY: startY,
        didDrawCell: function (data) {
          console.log(data)
          if (data.section === 'body' && data.row.index === body.length - 1) {
            data.cell.colSpan = 3;
           }
        },
        margin: { horizontal: 2 },
        styles: {
            columnWidth: 'wrap',
            fontSize: 12,
            overflow: 'linebreak',
            cellWidth: 'auto',
        },
        columnStyles: {
            2: { cellWidth: 'auto' },
            nil: { halign: 'center' },
            tgl: { halign: 'center' },
        },
        headerStyles: {
            halign: 'center',
            valign: 'middle',
            fillColor: [0, 0, 0],
            textColor: [255, 255, 255],
            lineColor: [1, 1, 1],
        },
        bodyStyles: { lineColor: [1, 1, 1] },
        theme: 'grid',
    });
    startY=doc.autoTable.previous.finalY+5;
    // Check if the table exceeds the height of the page and add a new page if needed
    if (startY>=150) {
    doc.addPage(); // Add a new page
    }
    startY = doc.autoTable.previous.finalY+5; // Reset startY for the new page
    doc.text(`Total Quantity Sold: ${this.sale.sales_items.reduce((acc,item)=>acc+item.quantity,0)}`, 2, startY);
    // Add more details like tax, discounts, etc.
    // Add text "Thanks for shopping with us" at the bottom
    const textX = 10;
    const textY = doc.internal.pageSize.height - 10; // Adjust position as needed
    doc.line(textX, textY-10, textX+100, textY-10);
    doc.setFontSize(12);
    doc.addFont("Tahoma", "Tahoma", "bold");
    doc.text(middle_w-35, textY, 'THANKS FOR SHOPPING WITH US');
    // Generate the preview link
    const previewLink=doc.output("bloburl");
    var openeddoc = window.open(
        previewLink,
        "Receipt",
        "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=000,left=500,width=1000,height=1500"
    );
    openeddoc.focus();
    this.sale=null;
  },
  },
};
</script>

<template>
  <div></div>
</template>
<style>
</style>