<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import Receipt from "@/views/pages/pos/printReceipt.vue";
import reportdet from "@/components/report/header";
import AcceptReturn from "@/views/pages/pos/parts/AcceptReturn.vue";
import moment from "moment";

export default {
  page: {
    title: "Sales Returns List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Receipt, reportdet,AcceptReturn },
  data() {
    return {
      title: "Sales Returns List",
      items: [
        {
          text: "Sales Returns",
        },
        {
          text: "Sales Returns List",
          active: true,
        },
      ],
      headers: null,
      uniqueCars: null,
      showme: true,
      pl: "",
      returnslist: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 500, 1000, 1500, 2000],
      filter: null,
      filterOn: [],
      sortBy: "return_id",
      sortDesc: false,
      total: 0,
      sale:0,
      returnitems: [],
      paymentMethod: "",
      status: "",
      fromdate: "",
      todate: "",
      limit: 12,
      offset: 0,
      receiptHeaders: [
        { text: "Title", value: "title", sortable: true },
        { text: "retail_price", value: "retail_price", sortable: true },
        { text: "Quantity", value: "quantity", sortable: true },
      ],
      paycode: "",
      fields: [
        { key: 'date', label: 'Date', sortable: true },
        { key: 'action', label: 'Action', sortable: false },
        { key: 'invoice_no', label: 'Invoice No.', sortable: true },
        { key: 'return', label: 'Parent Sale.', sortable: true },
        { key: 'supplier.name', label: 'Customer Name', sortable: true },
        { key: 'location.name', label: 'Location', sortable: false },
        { key: 'payment_status', label: 'Payment Status', sortable: true },
        { key: 'reason', label: 'Return Reason', sortable: true },
        { key: 'return_amount', label: 'Total Amount', sortable: true },
        { key: 'return_amount_due', label: 'Payment Due', sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.returnslist.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.updatearrays();
    this.totalRows = this.returnslist.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatearrays() {
      Swal.fire({
        title: "Please Wait !",
        html: "Loading data...", // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .get(
          `salesreturns/?limit=${this.limit}&offset=${this.offset}&status=${this.status}&fromdate=${this.fromdate}&todate=${this.todate}`
        )
        .then((response) => {
          // JSON responses are automatically parsed.
          this.returnslist = response.data
          this.returnslist=this.returnslist.map(item => ({
              ...item,
              date: moment(item.date).format('YYYY-MM-DD HH:mm:ss') // Adjust the format as needed
          }));         
        Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.limit = this.perPage;
      this.offset = (this.currentPage - 1) * this.perPage;
      this.updatearrays();
    },
    printpdf(pl) {
      //console.log(this.tokenString);
      this.pl = pl;
      const data = this.returnslist.map((row) => ({
        ID: row.id,
        "Invoice ID": row.return_id,
        "Return Date": row.date,
        "Total Amount": row.return_amount,
        status: row.payment_status,
      }));

      //get headers
      const headers = Object.keys(data[0]);
      const cars = [];
      Object.entries(data).forEach((val) => {
        const [key, value] = val;
        console.log(key, value);
        cars.push(Object.values(data[key]));
      });

      const uniqueCars = Array.from(new Set(cars));
      this.headers = headers;
      this.uniqueCars = uniqueCars;
      //alert(headers);
    },

    getrpt() {
      //alert(new Date());
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const date = d.getDate();

      const hour = d.getHours();
      const min = d.getMinutes();
      const sec = d.getSeconds();
      const msec = d.getMilliseconds();
      const filename =
        year + "-" + month + "-" + date + "-" + hour + "-" + min + "-" + sec + "-" + msec;
      //alert(filename);
      const data = this.returnslist.map((row) => ({
        ID: row.id,
        "Invoice ID": row.return_id,
        "Return Date": row.date,
        "Total Amount": row.return_amount,
        status: row.payment_status,
      }));
      //alert("");
      const csvRows = [];
      //get headers
      const headers = Object.keys(data[0]);

      csvRows.push(headers.join(","));
      //alert(csvRows);
      //loop over the headers
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          // alert(escaped);
          return '"' + escaped + '"'; //'" + escaped + "';
        });
        csvRows.push(values.join(","));
      }
      //alert(csvData);
      const csvData = csvRows.join("\n");
      //alert(csvData);

      const blob = new Blob([csvData], { type: "textcsv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", this.title + filename + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    deleterec(index, id, code) {
      Swal.fire({
        title: "Are you sure, you want to delete sale return #" + code + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("salesreturns/" + id + "/")
            .then(() => {
              this.returnslist.splice(index, 1);
              Swal.fire("Deleted!", code + " has been deleted.", "success");
            })
            .catch((e) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error!",
                html: e,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    openDialog(index, id) {
      Swal.fire({
        title: "Enter transaction code?",
        input: "text",
        inputPlaceholder: "Enter transaction code here",
        showCancelButton: true,
        confirmButtonText: "Complete Sale",
        cancelButtonText: "Cancel",
        inputValidator: (value) => {
          // Perform any validation on the user input
          if (!value) {
            return "Please enter transaction code to continue!";
          } else if (value == "NULL") {
            return `Transaction code cannot be ${value}!`;
          } else if (value.length < 5) {
            return `Transaction code ${value} is too short!`;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.addPayment(index, id, result.value);
        }
      });
    },
    async handleOk(){
      // Emit a custom event to the child component
      await this.$emit('confirmRefund');   
      //$event.preventDefault();
    },
    viewReceipt(saleitem) {
      this.total = saleitem.return_amount;
      this.receiptNo = saleitem.return_id;
      this.paymentMethod = 'cash';
      let items = [];
      saleitem.return_items.forEach((i) => {
        items.push({
          product_title: i.product_title,
          quantity: i.quantity,
        });
      });
      this.salesitems = items;
      this.$emit("printReceipt");
    },
    saleRefund(sale){
    this.sale=sale;
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row justify-content-between">
      <div class="col-sm-6">
        <div class="float-end">
          <div class="form-inline mb-3">
            <div
              class="input-daterange input-group"
              data-provide="datepicker"
              data-date-format="dd M, yyyy"
              data-date-autoclose="true"
            >
              <div class="form-group">
                <label for="transactionType">Status</label>
                <select v-model="status" class="form-control">
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="due">Due</option>
                  <option value="draft">Draft</option>
                  <option value="final">Final</option>
                  <option value="overdue">OverDue</option>
                  <option value="partial">Partial</option>
                </select>
              </div>
              <input
                type="date"
                class="form-control text-left"
                placeholder="11/13/2023"
                name="From"
                v-model="fromdate"
              />
              <input
                type="date"
                class="form-control text-left"
                placeholder="11/13/2023"
                name="To"
                v-model="todate"
              />
              <div class="input-group-append">
                <button type="button" class="btn btn-primary" @click="updatearrays()">
                  <i class="mdi mdi-filter-variant"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @input="handlePageChange(currentPage)"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="returnslist"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(check)="data">
          <div class="custom-control custom-checkbox text-center">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`contacusercheck${data.item.id}`"
            />
            <label
              class="custom-control-label"
              :for="`contacusercheck${data.item.id}`"
            ></label>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.item.id }}
          </a>
        </template>

        <template v-slot:cell(payment_status)="data">
          <div
            class="badge rounded-pill bg-soft-danger font-size-12"
            v-if="data.item.payment_status === 'pending'"
          >
            {{ data.item.payment_status }}
          </div>
          <div
            class="badge rounded-pill bg-soft-warning font-size-12"
            v-if="data.item.payment_status === 'due'"
          >
            {{ data.item.payment_status }}
          </div>
          <div
            class="badge rounded-pill bg-soft-success font-size-12"
            v-if="data.item.payment_status === 'paid'"
          >
            {{ data.item.payment_status }}
          </div>
        </template>
        <!-- s -->
        <template v-slot:cell(download)="data">
          <div>
            <button
              class="btn btn-light btn-sm w-xs"
              v-b-modal.modal-receipt
              @click="viewReceipt(data.item), $emit('printReceipt')"
            >
              <i class="uil uil-file ms-2 text-primary"></i>
            </button>
          </div>
        </template>
        <template v-slot:cell(invoice_no)="data">
          <a href="javascript: void(0);" class="link fw-bold">
            {{ data.item.invoice_no }}
          </a>
        </template>
        <template v-slot:cell(parent_sale)="data">
          <a href="javascript: void(0);" class="link fw-bold">
            {{ data.item.parent_sale }}
          </a>
        </template>
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <b-dropdown class="list-inline-item" variant="white" right
            toggle-class="text-muted font-size-18 px-2">
            <template v-slot:button-content>
              <i class="uil uil-ellipsis-v"></i>
            </template>
            <a href="#" class="dropdown-item uil uil-eye">View</a>
            <a href="#" class="dropdown-item" v-b-modal.modal-Add @click="edit(data)">Edit</a>
            <a href="#" class="dropdown-item uil uil-trash-alt" @click="deleterec(data.index,data.item.id,data.item.invoice_no)">Delete</a>
            <a href="#" class="dropdown-item uil uil-check-circle" @click="saleRefund(data.item)" v-b-modal.modal-refund v-if="data.item.payment_status !=='paid'">Accept Return</a>
            <a href="#" class="dropdown-item uil uil-eye">View Payments</a>
          </b-dropdown>
        </ul>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!--modals-->
    <b-modal
      id="modal-refund" 
      ref="modal-return"
      title="Accept Return"
      size="xl" class="" 
      ok-title="Save"
      cancel-title="Cancel"
      footer-button-variant="primary"
      ok-variant="success"
      cancel-variant="danger"
      @ok="handleOk($event)"
      >
      <AcceptReturn
        :sale="sale"
        @confirmRefund="handleOk($event)"
        @update-array="updatearrays()"
      />
    </b-modal>
    <b-modal id="modal-receipt" ref="modal" title="Print Receipt" size="lg" class="">
      <Receipt
        :items="salesitems"
        :headers="receiptHeaders"
        :total="total"
        :paymentMethod="paymentMethod"
        :receiptNo="paycode"
        @printReceipt="viewReceipt"
      />
    </b-modal>
    <b-modal id="modal-Print" title="Print PDF" hide-footer size="bg" centered>
      <reportdet
        :title="title"
        :orderData="orderData"
        :pl="pl"
        :headers="headers"
        :uniqueCars="uniqueCars"
        :shome="showme"
        v-show="showme"
      ></reportdet>
    </b-modal>
  </Layout>
</template>
