<template>
    <div class="conatiner">
    <v-card class="border border-solid border-2 rounded-1 border-warning">
      <v-card-title>
        <div class="row">
          <div class="col-sm-12"></div>
          <div class="row">
            <div class="col-sm-4 bg-light rounded-2 border border-light border-2">
              <h4>Customer:<strong>{{ sale.customer.name }}</strong></h4>
              <h4>Business:<strong>{{ sale.date }}</strong></h4>
            </div>
            <div class="col-sm-4 bg-light rounded-2">
              <h4>Invoice No:<strong>{{ sale.invoice_no }}</strong></h4>
              <h4>Location:<strong>{{ sale.location.name}}({{ sale.location.id }})</strong></h4>
            </div>
            <div class="col-sm-4 bg-light rounded-2">
              <h4>Total Amount:<strong>{{ new Intl.NumberFormat().format(sale.return_amount_due) }}</strong></h4>
              <h4>Note:<span>---</span></h4>
            </div>
          <div class="card">
            <div class="card-title">
                <h4>Advance Amount:<strong>0.00</strong></h4>
            </div>
            <div class="row">
                <div class="col-lg-6 p-1">
                    <label>Refund Method:</label>
                    <div class="mb-3">
                        <multiselect
                        v-model="refund_method"
                        :options="refund_methods"
                        :multiple="false"
                        label="title"
                        track-by="title"
                        placeholder="Select Mehtod"
                        class=""
                     ></multiselect>
                    </div>
                </div>
                <div class="col-lg-6 p-1">
                    <label>Amount:{{ refundTotal }}</label>
                    <div class="mb-3">
                        <input type="number" v-model="refundTotal" class="form-control" />
                    </div>
                </div>
                <div class="col-lg-6 p-1">
                    <label>Payment Account:</label>
                    <div class="mb-3">
                        <multiselect
                        v-model="pay_account"
                        :options="pay_accounts"
                        :multiple="false"
                        label="title"
                        track-by="title"
                        :editable="true"
                        placeholder="Select..."
                        class=""
                        disabled
                     ></multiselect>
                    </div>
                </div>
                <div class="col-lg-6 p-1">
                    <label>Attachment:</label>
                    <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="inputGroupFile" @change="handleFileUpload">
                    </div>
                    </div>
                </div>
                <div class="col-lg-6">
                <div class="mb-3">
                    <label>Payment Note:</label>
                    <b-form-textarea
                    v-model="pay_note"
                    class="form-control mr-sm-2 d-inline-block"
                    placeholder="Enter your text here..."
                    rows="3"
                    ></b-form-textarea>
                </div>
                </div>
            </div>
          </div>
          </div>
        </div>
      </v-card-title>
      </v-card>
        <Receipt
        :items="returncart"
        :headers="receiptHeaders"
        :total="Number(refundTotal).toFixed(2)"
        :paymentMethod="refund_method.title"
        :receiptNo="paycode"
        @printReceipt="submitRefund"
        v-show="false"
        />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Receipt from "../printReceipt.vue";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";

export default {
  name: "PosCart",
  props:{
    sale:Object,
  },
  data() {
    return {
      receiptHeaders: [
        { text: "Product Name", value: "title", sortable: true },
        { text: "Unit Price", value: "unit_price", sortable: true },
        { text: "Sale Quantity", value: "quantity", sortable: true },
        { text: "Return Subtotal", value: "return_subtotal", sortable: true },
      ],
      refund_method: {'title':'Credit Customer Account','value':'credit'},
      refund_methods: [
        {'title':'Cash','value':'cash'},
        {'title':'Bank Transfer','value':'bank'},
        {'title':'Debit Customer Account','value':'debit'},
        {'title':'Credit Customer Account','value':'credit'},
        {'title':'Credit Customer Advance Account','value':'pay_cash_to_customer_account'}
        ],
        pay_accounts: [
            {'title':'None','value':null},
        ],
        pay_account:null,
        pay_document:null,
        refundTotal:0,
        pay_note:"Paid in full",
        beep_warning_sound: require("@/assets/audio/beep-warning.mp3"),
        beepConfirmSound: require("@/assets/audio/confirm.mp3"),
    };
  },
  components: {
    Multiselect,
    Receipt,
  },
  computed: {
  },
  created(){
  },
  mounted() {
    this.refundTotal=parseFloat(this.sale.return_amount_due);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    this.$parent.$off('confirmRefund', this.handleRefund());
  },
  methods: {
    handleFileUpload($event) {
      console.log($event)
      this.pay_document = $event.target.files[0];
      console.log('Selected file:', this.pay_document);
    },
    playBeepSound(audio_url) {
      const audio = new Audio(audio_url);
      audio.play();
    },
    submitRefund(data) {
      axios
        .post(`salesreturns_refunds/`,data)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: res.data.icon,
            title: res.data.title,
            html: res.data.msg,
            showConfirmButton: false,
            timer: 1500,
          }).then(()=>{
          this.$emit("printReceipt");
          this.$emit("update-array");
          });
          this.playBeepSound(this.beepConfirmSound);
          this.clearValues();
        })
        .cath((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
            timer: 3000,
          });
          this.playBeepSound(this.beep_warning_sound);
        });
    },
    handleRefund() {
      var formdata = new FormData();
      formdata.append("return_id", this.sale.invoice_no);
      formdata.append("pay_document",this.pay_document);
      formdata.append("pay_note",this.pay_note);
      formdata.append("refund_method",this.refund_method.value);
      formdata.append("invoice_no", this.sale.invoice_no);
      formdata.append("refund_total",Number(this.refundTotal).toFixed(2));
      formdata.append("attendant_id",JSON.parse(sessionStorage.user).user.id)
      this.submitRefund(formdata);
    },
    handleSubmit() {},
    clearValues() {
        //
    },
  },
};
</script>